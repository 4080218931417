import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#4eabdd',
          secondary: '#151b42',
          accent: '#8c9eff'
          //  error: '#da0000'
        }
      }
    }
})