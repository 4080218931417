<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet
          min-height="calc(100vh - 88px)"
          rounded="lg"
          class="px-5 pt-5 position-txt"
        >
          <h3>Gestione palmari</h3>

          <v-progress-circular
            class="new-inv"
            indeterminate
            size="64"
            v-if="palmariLoaded == false"
          ></v-progress-circular>

          <v-combobox
            class="mr-5"
            v-model="model"
            :items="seriali"
            :search-input.sync="search"
            label="Seleziona o inserisci i seriali"
            chips
            deletable-chips
            clearable
            full-width
          />

          <h1
            class="no-results"
            v-if="!model && !loading"
          >
            Seleziona un seriale
          </h1>

          <h1
            class="no-results"
            v-if="loading"
          >
            <v-progress-circular
              style="width: 100%;"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <br>
            Caricamento...
          </h1>

          <v-container v-if="model && !loading">
            <!-- DATI GENERALI-->
            <v-row>
              <v-col class="d-flex align-center">
                <h3>Info Generiche</h3>
                <v-btn icon @click="openDialogModifica">
                  <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="overline">
              <v-col cols="12" sm="3">
                <span>Seriale: {{ serialeSelezionato }}</span>
                <v-btn icon @click="openSplashtop()"
                  ><v-icon color="secondary">mdi-monitor</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <span>SIM Serial: {{ serialResults.sim_serial }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>IP: {{ serialResults.ip }}</span>
              </v-col>
              <v-col cols="12" sm="2">
                <span>mTRUCKER4: {{ serialResults.versione_mTrucker4 }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span
                  >Filiale: {{ serialResults.nm_filiale }} ({{
                    serialResults.cd_filiale
                  }})</span
                ></v-col
              >
              <v-col cols="12" sm="3">
                <span>
                  Stato: 
                  <v-chip
                    :color="getColoreStato()"
                    dark
                  >
                    {{ getNomeStato() }}
                  </v-chip>
                </span>
              </v-col>
            </v-row>
            <!-- ULTIMI DATI-->
            <v-row>
              <v-col>
                <h3>Ultimi Dati</h3>
              </v-col>
            </v-row>
            <v-row class="overline">
              <v-col cols="12" sm="3">
                <span>Login: {{ formatDate(serialResults.last_login) }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>Codice: {{ serialResults.username }}</span>
              </v-col>
              <v-col cols="12" sm="4">
                <span>
                  Posizione:
                  {{
                    serialResults.posizione
                      ? formatDate(serialResults.posizione.data_registrazione)
                      : ""
                  }}
                </span>
                <v-btn icon @click="openGoogle()" v-if="serialResults.posizione">
                  <v-icon color="secondary">mdi-map-marker</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- ULTIMI DATI-->
            <v-row>
              <v-col>
                <h3>Ultimo Inventario</h3>
              </v-col>
            </v-row>
            <v-row class="overline">
              <v-col cols="12" sm="3">
                <span>Filiale: {{ serialResults.inventario.cd_filiale }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>Inventario: {{ formatInventario(serialResults.inventario.cd_inventario) }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>Utente rilevamento: {{ serialResults.inventario.utente_rilevamento }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>Data rilevamento: {{ formatDate(serialResults.inventario.data_rilevamento) }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>Nota: {{ serialResults.inventario.nota }}</span>
              </v-col>
              <v-col cols="12" sm="3">
                <span>Previsto: </span>
                <v-avatar
                  :color="getColor(serialResults.inventario.previsto, serialResults.inventario.data_rilevamento)"
                  dark
                  size="26"
                >
                </v-avatar>
              </v-col>
            </v-row>
          </v-container>

          <!-- EDIT DIALOG -->
          <v-dialog v-model="dialogModifica" max-width="500px">
            <v-card>
              <v-card-title class="headline pt-4">Modifica {{ serialeSelezionato }}</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="dialogModificaEl.sim_serial"
                  label="Sim Serial"
                />
                <v-autocomplete
                  v-model="dialogModificaEl.cd_filiale"
                  :items="filiali"
                  item-text="select_name"
                  item-value="codice"
                  label="Filiale"
                  auto-select-first
                />
                <v-row class="align-center mt-3">
                  <span 
                    style="font-size: 16px;"
                    class="ml-3"
                  >
                    Cancellato
                  </span>
                  <v-checkbox
                    v-model="dialogModificaEl.stato"
                    hide-details
                    class="shrink ma-0 ml-2 pa-0"
                    style="width: min-content !important;"
                  ></v-checkbox>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeDialogModifica"
                  >Chiudi</v-btn
                >
                <v-btn color="primary" text @click="saveDialogModifica" :disabled="dialogModificaLoading" :loading="dialogModificaLoading"
                  >Salva</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- EXPORT DIALOG -->
          <v-dialog v-model="dialogCSV" max-width="500px">
            <v-card>
              <v-card-title class="headline pt-4">Download CSV</v-card-title>
              <v-card-text>
                <div class="mt-4">
                  <p style="display: inline">File .csv pronto!</p>
                  <a
                    :download="'Utenti-' + dateNow + '.csv'"
                    :href="fileCSV"
                    style="text-decoration: none; display: inline"
                    class="ml-4"
                  >
                    <v-btn @click="dialogCSV = false" color="primary">
                      Download
                      <v-icon right> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogCSV = false"
                  >Chiudi</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var dayjs = require("dayjs");

export default {
  name: "GestionePalmari",

  data() {
    return {
      palmariLoaded: false,
      palmari: [],
      seriali: [],
      filiali: [],
      loading: false,
      serialeSelezionato: "",
      itemsPerPage: 15,
      model: "",
      search: null,
      serialResults: [],
      dialogCSV: false,
      dialogModifica: false,
      dialogModificaEl: {},
      dialogModificaElDefault: {
        sim_serial: "",
        cd_filiale: "",
        stato: false
      },
      dialogModificaLoading: false,
      dateNow: "",
      fileCSV: null,
    };
  },
  mounted() {
    this.getPalmari();
    this.getFiliali();
  },
  watch: {
    model: function () {
      if (!this.model) {
        this.serialResults = [];
        this.serialeSelezionato = "";
      } else {
        this.serialeSelezionato = this.model.split(" - ")[0];
        this.getDettaglioPalmare();
      }
    },
  },
  methods: {
    getPalmari() {
      axios
        .get(
          `${
            this.$store.state.url
          }getPalmari.php?cd_filiale=${localStorage.getItem("cd_filiale")}`
        )
        .then((response) => {
          this.palmari = [...response.data];
          this.palmari.forEach((element) => {
            this.seriali.push(`${element.seriale} - ${element.cd_filiale}`);
          });
          this.palmariLoaded = true;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.palmariLoaded = true;
        });
    },
    getDettaglioPalmare() {
      this.loading = true
      axios
        .get(
          `${this.$store.state.url}getDettaglioPalmare.php?seriale=${this.serialeSelezionato}`
        )
        .then((response) => {
          this.serialResults = response.data;
          console.log(this.serialResults);
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => {
          this.loading = false
        });
    },
    getFiliali() {
      axios
        .get(`${this.$store.state.url}getFiliali.php`)
        .then((response) => {
          this.filiali = response.data.filter((user) => user.tipologia == 0)
          // this.aree = response.data.filter((user) => user.tipologia == 1);
          // this.aree.map((el) => {el.n_filiali = this.filiali.filter(obj => obj.cod_id == el.id_filiale).length})
          // this.codici_filiali_aree = response.data.map((obj) => obj.codice);

          this.filiali = this.filiali.map(el => {
            var return_el = el
            return_el["select_name"] = `${el.nm_filiale.toUpperCase()} (${el.codice})`
            return return_el
          })

          console.log(this.filiali)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openSplashtop() {
      window.open(
        `splashtop-business://com.splashtop.business?ignorecert=yes&sn=${this.serialeSelezionato}`,
        "_blank"
      );
    },
    openGoogle() {
      window.open(`http://maps.google.com/maps?q=loc:${this.serialResults.posizione.latitudine},${this.serialResults.posizione.longitudine}`, "_blank")
    },
    openDialogModifica() {
      this.dialogModificaEl = {
        sim_serial: this.serialResults["sim_serial"],
        cd_filiale: this.serialResults["cd_filiale"],
        stato: this.serialResults["stato"] == "d"
      }
      this.dialogModifica = true
    },
    closeDialogModifica() {
      this.dialogModificaEl = Object.assign({}, this.dialogModificaElDefault)
      this.dialogModifica = false
    },
    saveDialogModifica() {
      var params = Object.assign({}, this.dialogModificaEl)
      params["seriale"] = this.serialeSelezionato
      params["stato"] = this.dialogModificaEl["stato"] ? 'd' : 'u'

      this.dialogModificaLoading = true
      
      axios
        .put("https://palmariarco.newtonsrl.eu/SORGENTI/999999/updatePalmare.php", params)
        .then(() => {
          this.closeDialogModifica()
          this.getDettaglioPalmare()
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.dialogModificaLoading = false
        })
    },
    formatDate(date) {
      if (date != "") {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      }
    },
    formatInventario(cd_inventario) {
      var year = cd_inventario.substr(0, 4);
      var filiale = cd_inventario.substr(4, 3);
      var number = cd_inventario.substr(7);
      return `${year}-${filiale}-${number}`;
    },
    getColor(previsto, data) {
      if (data == null || data == undefined || data == "") return "red";
      else if (previsto == "1") return "green";
      else return "orange";
    },
    getData(e) {
      this.currentItems = e;
    },
    getNomeStato() {
      if (this.serialResults.stato == 'd') {
        return "CANCELLATO"
      }
      else if (this.serialResults.stato == 'u') {
        return "OPERATIVO"
      }
      else {
        return this.serialResults.stato
      }
    },
    getColoreStato() {
      if (this.serialResults.stato == 'd') {
        return "red"
      }
      else if (this.serialResults.stato == 'u') {
        return "green"
      }
      else {
        return "grey"
      }
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;
      this.$nextTick(() => {
        csv += `"Seriale";"Codice filiale";"Codice inventario";"Utente rilevamento";"Data rilevamento";"Nota";"Previsto"\n`;

        this.currentItems.forEach(function (row) {
          var previstoText;
          if (
            row["data_rilevamento"] == null ||
            row["data_rilevamento"] == undefined ||
            row["data_rilevamento"] == ""
          ) {
            previstoText = "Mancanza";
          } else if (row["previsto"] == "1") {
            previstoText = "Rilevato";
          } else {
            previstoText = "Eccedenza";
          }

          csv += `"${row["seriale"]}";"=""${row["cd_filiale"]}""";"${
            row["cd_inventario"]
          }";"${
            row["utente_rilevamento"] == null ? "" : row["utente_rilevamento"]
          }";"${
            row["data_rilevamento"] == null ? "" : row["data_rilevamento"]
          }";"${row["nota"] == null ? "" : row["nota"]}";"${previstoText}";\n`;
        });
        var csvData = new Blob([csv], { type: "text/csv" });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
  },
};
</script>

<style>
.v-input {
  width: 50% !important;
  max-width: 50% !important;
}

div.position-txt {
  min-height: calc(100vh - 128px);
  position: relative;
}

h1.no-results {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
}
.v-select__selections {
  overflow-y: auto;
  flex-wrap: nowrap;
}
.v-chip {
  overflow: initial;
}
</style>